import axiosIntents from "@/utils/axios-intents";
import { firestoreAction } from "vuexfire";
import { db } from "@/utils/firebase";

const state = {
  canInvite: false,
  canRequest: false,
  
  routes: [],
  invites: [],
  approved: [],
  
  loading: false,
  
  selectedRoute: '',
  routeToAccept: '',
}

const getters = {
  billingRoutesLoading: state => state.loading,
  
  getCanInvite: state => state.canInvite,
  getCanRequest: state => state.canRequest,
  
  getBillingRoutes: state => state.routes,

  getParentBillingRoutes: state => state.routes.filter(({RoutingType}) => RoutingType == 'parent'),
  hasParentBillingRoutes: (state, getters) => Boolean(getters.getParentBillingRoutes.length),

  getChildBillingRoutes: state => state.routes.filter(({RoutingType}) => RoutingType == 'child'),
  hasChildBillingRoutes: (state, getters) => Boolean(getters.getChildBillingRoutes.length),

  getBillingInvites: state => state.invites,
  getBillingApproved: state => {
    if (state.routes.some(({RoutingType}) => RoutingType === 'parent')) return []
    return state.approved
  },
  
  getSelectedRoute: state => state.selectedRoute,
  
  getSelectedRouteInfo: state => {
    const route = state.routes.find(r => r.UUID === state.selectedRoute)
    return {
      phone: route?.CreditAccountMSISDN ? `+${route?.CreditAccountMSISDN}` : '',
      email: route?.CreditAccount.Email ?? '',
      total: (route?.SpendLimit ?? 0) / 100,
      name: route?.CreditAccount.Name ?? '',
      cycle: route?.SpendCycleCount ?? '',
      frequency: route?.SpendCycle ?? '',
      DebitAccount: route?.DebitAccount?.UUID ?? '',
      CreditAccount: route?.CreditAccount?.UUID ?? '',
    }
  },
  
  getAcceptRouteInfo: state => {
    const route = state.approved.find(r => r.UUID === state.routeToAccept)
    return {
      customer: route?.RequestedByCustomerName ?? '',
      total: (route?.SpendLimit ?? 0) / 100,
      name: route?.Name ?? '',
      cycle: route?.SpendCycleCount ?? 0,
      frequency: route?.SpendCycle ?? '',
    }
  },
  
  showCreateRouteBtn: state => {
    return !state.loading && (state.canInvite || state.canRequest)
  },
  
  showEmptyWarning: state => {
    return (
      !state.loading &&
      !Boolean(state.routes.length)
    )
  },
  
  showRadio: state => state.canInvite && state.canRequest,
  
  getTotalRoutesLength: state => `${state.routes.length}-${state.invites.length}-${state.approved.length}`
}

const mutations = {
  setBillingRoutesLoading(state, payload) {
    state.loading = payload
  },
  
  setCanInvite(state, payload){
    state.canInvite = payload
  },
  setCanRequest(state, payload){
    state.canRequest = payload
  },
  
  setBillingInvites(state, payload){
    state.invites = payload
  },
  setBillingApproved(state, payload){
    state.approved = payload
  },
  
  setSelectedRoute(state, payload) {
    state.selectedRoute = payload
  },
  
  setRouteToAccept(state, payload) {
    state.routeToAccept = payload
  }
}

const actions = {
  getBillingRoutesFromApi({getters, commit}, forced) {
    const loading = getters.billingRoutesLoading;
    if (!loading || forced) {
      const customerId = getters.current_account_uid;
      const requestToken = JSON.parse(sessionStorage.getItem('approval_token') ?? '""')
      let requestsUrl = `/customers/${customerId}/billing-routes/requests`
      if (requestToken) {
        requestsUrl += `?ApprovalCode=${requestToken}`
      }
    //   console.log({requestToken, requestsUrl})
      commit('setBillingRoutesLoading', true)
      return Promise
        .all([
          axiosIntents.get(`/customers/${customerId}/billing-routes`),
          axiosIntents.get(requestsUrl)
        ])
        .then(([routes, requests]) => {
          const {CanInvite, CanRequest} = routes.result || {}
          const {Invites = [], Approvals = []} = requests.result || {}
          
          commit('setCanInvite', CanInvite)
          commit('setCanRequest', CanRequest)
          commit('setBillingInvites', Invites)

          const ApprovalsToSet = (CanInvite && !CanRequest) ? Approvals.filter(approval => approval.Type == 'request')  : Approvals

          commit('setBillingApproved', ApprovalsToSet)
        })
        .catch((getRoutesError) => console.warn({getRoutesError}))
        .finally(() => commit('setBillingRoutesLoading', false))
    }
  },
  
  createRoute({getters, commit, dispatch}, { phone, mobile_product_uuid, name, type } = {}) {
    const SPID = getters.app_spid
    const CustomerUUID = getters.current_account_uid
    const RequestedByProductUUID = getters.customer_current_product_uuid

    const req = {
        SPID,
        CustomerUUID,
        RequestedByProductUUID,
        RequestedTo: phone || mobile_product_uuid,
        Description: name,
        ExpiresAtNanos: (new Date().getTime() + 60 * 60 * 1000) * 1000000,
        ApprovalType: type == 'invite' ? 'BILLING_INVITE' : 'BILLING_REQUEST',
    }

    commit('setBillingRoutesLoading', true)

    return dispatch('api_approval/Invite', req).then(result => {
        return dispatch('getBillingRoutesFromApi', true)
    }).catch(createRouteError => {
        console.log('createRouteError', createRouteError)

        return Promise.reject(createRouteError)
    }).finally(()=> {
        commit('setBillingRoutesLoading', false)
    })
  },
  
  updateRoute({getters, commit}, payload) {
    const customerId = getters.current_account_uid
    const routeId = getters.getSelectedRoute
    commit('setBillingRoutesLoading', true)
    
    const newPayload = {
      Name: payload.name ?? '',
      SpendCycle: payload.frequency.field ?? '',
      SpendCycleCount: parseInt(payload.cycle || 0),
      SpendLimit: parseInt(payload.total || 0) * 100,
    }
    
    return new Promise((resolve, reject) => {
      axiosIntents.put(`/customers/${customerId}/billing-routes/${routeId}`, newPayload)
        .then(({ result }) => {
          resolve('Route updated', result)
        })
        .catch(updateRouteError => {
          reject(updateRouteError)
        })
        .finally(() => {
          commit('setBillingRoutesLoading', false)
        })
    })
  },
  
  deleteRoute({getters, commit, dispatch}) {
    const CustomerUUID = getters.current_account_uid
    const BillingRouteUUID = getters.getSelectedRoute
    commit('setBillingRoutesLoading', true)
    const { DebitAccount, CreditAccount } = getters.getSelectedRouteInfo
    
    const params = new URLSearchParams({
      DebitAccount,
      CreditAccount,
    }).toString()
    
    return new Promise((resolve, reject) => {
      axiosIntents.delete(`/customers/${CustomerUUID}/billing-routes/${BillingRouteUUID}?${params}`)
        .then(({ result }) => {
          commit('setSelectedRoute', '')
          dispatch('getBillingRoutesFromApi', true)
          resolve('Route deleted', result)
        })
        .catch(deleteRouteError => {
          reject(deleteRouteError)
        })
        .finally(() => {
          commit('setBillingRoutesLoading', false)
        })
    })
  },
  
  routeApprove({getters, commit, dispatch}, payload){
    const customerId = getters.current_account_uid
    
    commit('setBillingRoutesLoading', true);
    return new Promise((resolve, reject) => {
      axiosIntents.post(`/customers/${customerId}/billing-routes/approval/action`, payload)
        .then(() => {
          dispatch('getBillingRoutesFromApi', true)
          sessionStorage.removeItem('approval_token')
          resolve('Action applied') })
        .catch(applyActionError => {
          commit('setBillingRoutesLoading', false)
          reject(applyActionError)
        })
        .finally(() => {})
      })
  },
  
  selectRoute({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit('setSelectedRoute', payload)
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })},
  
  selectRouteToAccept({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit('setRouteToAccept', payload)
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })},
  
  BindBillingRoutes: firestoreAction(({ bindFirestoreRef, getters }, current_account_uid) => {
    return bindFirestoreRef('routes',
      db
        .collection(getters.collection_customers_name)
        .doc(current_account_uid)
        .collection('billing_routes')
    )
  }),
  
  UnbindBindBillingRoutes: firestoreAction(({ unbindFirestoreRef }) => {
    return unbindFirestoreRef('routes');
  }),
  
  acceptRouteByToken({getters, dispatch}) {
    const routesToApprove = getters.getBillingApproved
    const requestToken = JSON.parse(sessionStorage.getItem('approval_token') ?? '""');
    const desiredRoute = routesToApprove.find(r=> r.ApprovalCode === requestToken);
    return new Promise((resolve, reject) => {
      if (desiredRoute) {
        const payload = {
          Action: "APPROVE",
          ApprovalUUID: desiredRoute.UUID,
          SpendCycle: desiredRoute.SpendCycle,
          SpendCycleCount: desiredRoute.SpendCycleCount,
          SpendLimit: desiredRoute.SpendLimit,
          Type: desiredRoute.Type,
          Name: desiredRoute.Name
        }
        dispatch('routeApprove', payload).then(() => {
          resolve('Success')
        }).catch(() => {
            reject('acceptRouteByToken Error')
        })
      } else {
        reject('no_invite')
      }
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
}
