import Vue from 'vue';
import VueRouter from 'vue-router';
import permissions from './permissions';
import store from '@/store';
import deepLinks from '@theme/data/deep-links.js'

Vue.use(VueRouter);

const routes = [
  // Deep links
  ...(process.env.VUE_APP_IS_DEEP_LINKS_ALLOWED === 'true' ? deepLinks(store) : []),
  {
    path: '/',
    component: () => import(/* webpackChunkName: "HomeLayouts" */ '@/views/layouts'),
    children: [
      { path: '', name: 'home', component: () => import(/* webpackChunkName: "Home" */ '@theme/home') },

      {
        path: 'plans/:plan?',
        name: 'plans',
        component: () => import(/* webpackChunkName: "HomePlans" */ '@/views/sim-purchase'),
        meta: { keepScrollPosition: true },
        beforeEnter: permissions(['is-show-plans-or-mobile-onboarding']),
      },

      {
        path: 'sims-multi-plans/:plan?',
        name: 'sims-multi-plans',
        component: () => import(/* webpackChunkName: "SimsMultiPlans" */ '@/views/sim-purchase'),
        meta: { keepScrollPosition: true },
        beforeEnter: permissions(['is-sims-multi-plans-allowed', 'is-sim-purchasing-allowed']),
      },
      
      {
          path: 'international-plans',
          name: 'international-plans',
          component: () => import(/* webpackChunkName: "InternationalPlans" */ '@/views/international-plans'),
          props: true,
          beforeEnter: permissions(['is-international-plans-allowed']),
      },

      {
        path: 'about',
        name: 'about',
        component: () => import(/* webpackChunkName: "About" */ '@/views/about'),
        beforeEnter: permissions(['!has-about-outer-link']),
      },

      { path: 'terms', name: 'terms', component: () => import(/* webpackChunkName: "Terms" */ '@/views/terms') },
      {
        path: 'terms/:terms_path',
        name: 'terms-special',
        component: () => import(/* webpackChunkName: "Special" */ '@/views/terms/special'),
        beforeEnter: permissions(['!is-prod']),
      },
	  { path: 'privacy', name: 'privacy', component: () => import(/* webpackChunkName: "Privacy" */ '@/views/privacy') },

      {
        path: 'help',
        name: 'help-center',
        component: () => import(/* webpackChunkName: "HelpCenter" */ '@/views/help-center'),
      },
      {
        path: 'help/category',
        component: () => import(/* webpackChunkName: "HelpCenterCategoryPage" */ '@/views/help-center/category-page'),
        children: [
            {
              path: ':category_id/',
              name: 'help-center-category',
            },
            // { path: ':category_id/faq', name: 'help-center-faq', component: () => import(/* webpackChunkName: "HelpCenterFaqPage" */ '@/views/help-center/faq-page') },
            { path: ':category_id/:section_id/:article_id?', name: 'help-center-article', component: () => import(/* webpackChunkName: "HelpCenterArticlePage" */ '@/views/help-center/article-page') },
        ],
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "HomeLayouts" */ '@/views/layouts'),
    props: { is_ufb: true },
    children: [
      { path: 'ufb', name: 'ufb', component: () => import(/* webpackChunkName: "Ufb" */ '@/views/ufb/landing') },
      {
        path: 'ufb-package',
        name: 'ufb-package',
        component: () => import(/* webpackChunkName: "UfbPlans" */ '@/views/package'),
        beforeEnter: permissions(['is-show-plans']),
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "HomeLayouts" */ '@/views/layouts'),
    props: { is_ufb: true, header: false },
    children: [
        { path: 
            'broadband',
            name: 'broadband',
            component: () => import(/* webpackChunkName: "Onboarding" */ '@/views/broadband'),
            beforeEnter: permissions(['is-show-ufb-module', '!is-account-suspended']),
        },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "SignInLayouts" */ '@/views/layouts'),
    props: { header: false },
    children: [
      { path: 'sign-in', name: 'sign-in', component: () => import(/* webpackChunkName: "SignIn" */ '@/views/sign-in') },
    //   { path: 'select-account', name: 'select-account', component: () => import('@/views/select-account') },
      { path: 'sign-up', name: 'sign-up', component: () => import(/* webpackChunkName: "SignUp" */ '@/views/sign-up') },
      { path: 'identity-link', name: 'sign-up-with-identity-link', component: () => import(/* webpackChunkName: "SignUpWithIdentityLink" */ '@/views/sign-up-with-identity-link') },
      { path: 'forgot-pass', name: 'forgot-pass', component: () => import(/* webpackChunkName: "ForgotPass" */ '@/views/forgot-pass') },
      { path: 'forgot-email', name: 'forgot-email', component: () => import(/* webpackChunkName: "ForgotEmail" */ '@/views/forgot-email') },
      { path: 'mobile-onboarding', name: 'mobile-onboarding', component: () => import(/* webpackChunkName: "MobileOnboarding" */ '@/views/onboarding/mobile-onboarding') },
    ],
    beforeEnter: permissions(['!is-account'], { name: 'home' }),
  },
  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "ProfileLayouts" */ '@/views/layouts'),
    // props: { header: { menu: [] } },
	props: { header: false },
    children: [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "Profile" */ '@/views/profile'),
        meta: { keepScrollPosition: true },
        children: [
          {
            path: 'account',
            component: () => import(/* webpackChunkName: "ProfileHome" */ '@/views/profile/home'),
            children: [
                { path: '', name: 'profile', beforeEnter: (to, from, next) => next({ name: 'profile-personal-data', replace: false }) },

                {
                    path: 'personal-data',
                    name: 'profile-personal-data',
                    component: () => import(/* webpackChunkName: "ProfilePersonalData" */ '@/views/profile/home/personal-data'),
                    children: [
                      {
                        path: 'auth-to-set-true-rewards-number',
                        name: 'auth-to-set-true-rewards-number',
                        component: () => import(/* webpackChunkName: "ProfileModalAuthToSetTrueRewardsNumber" */ '@/views/profile/home/personal-data/modal-auth-to-set-true-rewards-number'),
                      },
                      {
                        path: 'change-password',
                        name: 'change-password',
                        component: () => import(/* webpackChunkName: "ProfileModalChangePass" */ '@/views/profile/home/personal-data/modal-change-pass'),
                      },
                      {
                        path: 'change-email',
                        name: 'change-email',
                        component: () => import(/* webpackChunkName: "ProfileModalChangeEmail" */ '@/views/profile/home/personal-data/modal-change-email'),
                      },
                      {
                        path: 'change-notifications',
                        name: 'change-notifications',
                        component: () => import(/* webpackChunkName: "ProfileModalChangeNotifications" */ '@/views/profile/home/personal-data/modal-change-notifications'),
                      },
                    ],
                },

                {
                    path: 'customer-groups',
                    name: 'profile-customer-groups',
                    component: () => import(/* webpackChunkName: "ProfileCustomerGroups" */ '@/views/profile/home/groups/customer-groups.vue'),
                    beforeEnter: permissions(['is-customer-group-allowed']),
                },

                {
                    path: 'mobile-groups',
                    name: 'profile-mobile-groups',
                    component: () => import(/* webpackChunkName: "ProfileMobileGroups" */ '@/views/profile/home/groups/mobile-groups.vue'),
                    beforeEnter: permissions(['is-mobile-group-allowed', 'is-account-type-activated']),
                },

                {
                    path: 'shipping-details',
                    name: 'profile-shipping-details',
                    component: () => import(/* webpackChunkName: "ProfileShippingDetails" */ '@/views/profile/home/shipping-details'),
                },
                
                {
                    path: 'order-history',
                    name: 'profile-order-history',
                    component: () => import(/* webpackChunkName: "ProfileOrderHistory" */ '@/views/profile/home/order-history'),
                },

                {
                    path: 'payments-history',
                    name: 'profile-balance-payments-history',
                    component: () => import(/* webpackChunkName: "ProfilePaymentsHistory" */ '@/views/profile/balance/payments-history'),
                },

                {
                    path: 'redeem-voucher',
                    name: 'profile-redeem-voucher',
                    component: () => import(/* webpackChunkName: "ProfileRedeemVoucher" */ '@/views/profile/home/redeem-voucher'),
                    beforeEnter: permissions(['is-account-type-activated', 'is-voucher-allowed']),
                },

                {
                    path: 'payment-methods',
                    name: 'profile-balance-payment-methods',
                    component: () => import(/* webpackChunkName: "ProfilePaymentMethods" */ '@/views/profile/balance/payment-methods'),
                },

                {
                    path: 'invoices',
                    name: 'profile-account-invoices',
                    component: () => import(/* webpackChunkName: "ProfileAccountInvoices" */ '@/views/profile/home/invoices'),
                    beforeEnter: permissions(['is-invoices-allowed']),
                },

                {
                    path: 'receipts',
                    name: 'profile-account-receipts',
                    component: () => import(/* webpackChunkName: "ProfileAccountReceipts" */ '@/views/profile/home/receipts'),
                    beforeEnter: permissions(['is-receipts-allowed']),
                },

                {
                    path: 'pay-bill',
                    name: 'profile-account-pay-bill',
                    props: { is_on_profile_account_pay_bill: true },
                    props: true,
                    component: () => import(/* webpackChunkName: "ProfileAccountPayBill" */ '@/views/profile/plans/pay-bill'),
                    beforeEnter: permissions(['is-show-pay-bill-on-home']),
                },
            ],
            meta: { profile: 'personal-data' },
            beforeEnter: permissions(['is-allow-profile-tab']),
          },

          {
            path: 'account/invoices/:number',
            name: 'profile-account-invoice',
            component: () => import(/* webpackChunkName: "AccountInvoice" */ '@/views/profile/home/invoices/invoice.vue'),
            meta: { profile: 'personal-data' },
            beforeEnter: permissions(['is-allow-profile-tab', 'is-invoices-allowed']),
          },

          {
            path: 'account/receipts/:number',
            name: 'profile-account-receipt',
            component: () => import(/* webpackChunkName: "AccountReceipt" */ '@/views/profile/home/receipts/receipt.vue'),
            meta: { profile: 'personal-data' },
            beforeEnter: permissions(['is-allow-profile-tab', 'is-receipts-allowed']),
          },

        //   {
        //     path: 'balance',
        //     component: () => import(/* webpackChunkName: "Balance" */ '@/views/profile/balance'),
        //     children: [
        //       { path: '',
        //         name: 'profile-balance',
        //         beforeEnter: (to, from, next) => next({
        //             name: store.getters.is_account_postpay ? 'profile-balance-pay-bill' : 'profile-balance-top-up',
        //             replace: false
        //         })
        //       },

        //       { path: 'pay-bill',
        //         name: 'profile-balance-pay-bill',
        //         component: () => import(/* webpackChunkName: "PayBill" */ '@/views/profile/balance/pay-bill'),
        //         beforeEnter: permissions(['is-postpay']),
        //       },

        //       { path: 'top-up',
        //         name: 'profile-balance-top-up',
        //         component: () => import(/* webpackChunkName: "TopUp" */ '@/views/profile/balance/top-up'),
        //         props: true,
        //         beforeEnter: permissions(['!is-postpay', '!is_hide_prepay_topup']),
        //       },
        //      { path: 'voucher', name: 'profile-balance-voucher', component: () => import('@/views/profile/balance/voucher') },
        //     ],
        //     meta: { profile: 'balance' },
        //     beforeEnter: permissions(['is-allow-profile-tab']),
        //   },

          {
            path: 'balance/payment-methods/add',
            name: 'profile-balance-payment-methods-add',
            component: () => import(/* webpackChunkName: "AddPaymentMethod" */ '@/views/profile/balance/add-payment-method'),
          },

          {
            path: 'balance/billing-methods/add',
            name: 'profile-balance-billing-methods-add',
            component: () => import(/* webpackChunkName: "AddBillingMethod" */ '@/views/profile/balance/add-billing-method'),
            beforeEnter: permissions(['is-billing-routing-allowed']),
          },

          {
            path: 'balance/billing-methods/edit/:uuid',
            name: 'profile-balance-billing-methods-edit',
            component: () => import(/* webpackChunkName: "EditBillingMethod" */ '@/views/profile/balance/edit-billing-method'),
            beforeEnter: permissions(['is-billing-routing-allowed']),
          },

          {
            path: 'balance/billing-methods/accept/:uuid',
            name: 'profile-balance-billing-methods-accept',
            component: () => import(/* webpackChunkName: "AcceptBillingMethod" */ '@/views/profile/balance/accept-billing-method'),
            beforeEnter: permissions(['is-billing-routing-allowed']),
          },

          {
            path: 'bonuses',
            component: () => import(/* webpackChunkName: "Bonuses" */ '@/views/profile/bonuses'),
            children: [
              { path: '', name: 'profile-bonuses', beforeEnter: (to, from, next) => next({ name: 'profile-bonuses-rewards', replace: false }) },

              { path: 'rewards', name: 'profile-bonuses-rewards', component: () => import(/* webpackChunkName: "Rewards" */ '@/views/profile/bonuses/rewards') },
              { path: 'gifts', name: 'profile-bonuses-gifts', component: () => import(/* webpackChunkName: "Gifts" */ '@/views/profile/bonuses/gifts') },
            ],
            meta: { profile: 'bonuses' },
            beforeEnter: permissions(['is-allow-profile-tab']),
          },

          {
            path: 'bonuses/:uuid',
            name: 'profile-bonuses-view',
            component: () => import(/* webpackChunkName: "BonusView" */ '@/views/profile/bonuses/bonus-view'),
            meta: { profile: 'bonuses' },
            beforeEnter: permissions(['is-allow-profile-tab']),
          },

          {
            path: 'mobile',
            component: () => import(/* webpackChunkName: "Plans" */ '@/views/profile/plans'),
            children: [
              { path: '', name: 'profile-plans', beforeEnter: (to, from, next) => next({ name: 'profile-plans-dashboard', replace: false }) },

              { path: 'dashboard',
                alias: '/mobile-app-dashboard',
                name: 'profile-plans-dashboard',
                component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/profile/plans/dashboard'),
              },
              { path: 'your-plan', name: 'profile-plans-your-plan', component: () => import(/* webpackChunkName: "YourPlan" */ '@/views/profile/plans/your-plan') },
              { path: 'all-plans', name: 'profile-plans-all-plans', component: () => import(/* webpackChunkName: "AllPlans" */ '@/views/profile/plans/all-plans') },
              { path: 'addons', name: 'profile-plans-addons', component: () => import(/* webpackChunkName: "Addons" */ '@/views/profile/plans/addons') },

              { path: 'billing',
                name: 'profile-plans-pay-bill',
                component: () => import(/* webpackChunkName: "MobileBilling" */ '@/views/profile/plans/pay-bill'),
                beforeEnter: permissions(['is-postpay']),
              },

              { path: 'top-up',
                name: 'profile-plans-top-up',
                component: () => import(/* webpackChunkName: "MobileTopUp" */ '@/views/profile/plans/top-up'),
                props: true,
                beforeEnter: permissions(['!is-postpay', 'is-account-type-activated', '!is_hide_prepay_topup']),
              },
              
              { 
                path: 'usage',
                name: 'profile-plans-usage',
                props: true,
                component: () => import(/* webpackChunkName: "Usage" */ '@/views/profile/plans/usage')
              },
              {
                path: 'supp-features',
                name: 'profile-plans-supp-features',
                component: () => import(/* webpackChunkName: "SuppFeatures" */ '@/views/profile/plans/supp-features'),
              },
              {
                path: 'sim-card-actions',
                name: 'profile-plans-sim-card-actions',
                component: () => import(/* webpackChunkName: "SimCardActions" */ '@/views/profile/plans/sim-card-actions'),
                beforeEnter: permissions(['is-sim-actions-allowed']),
                children: [
                    {
                        path: 'disable-sim',
                        name: 'disable-sim',
                        component: () => import(/* webpackChunkName: "SimCardActionsDisableSim" */ '@/views/profile/plans/sim-card-actions/disable-or-enable-sim'),
                        beforeEnter: permissions(['is-sim-disable-enable-allowed']),
                    },
                    {
                        path: 'enable-sim',
                        name: 'enable-sim',
                        component: () => import(/* webpackChunkName: "SimCardActionsEnableSim" */ '@/views/profile/plans/sim-card-actions/disable-or-enable-sim'),
                        beforeEnter: permissions(['is-sim-disable-enable-allowed']),
                    },
                    {
                        path: 'sim-swap',
                        name: 'sim-swap',
                        component: () => import(/* webpackChunkName: "SimCardActionsSimSwap" */ '@/views/profile/plans/sim-card-actions/sim-swap'),
                        beforeEnter: permissions(['is-sim-swap-allowed']),
                    },
                    {
                        path: 'sim-details',
                        name: 'sim-details',
                        component: () => import(/* webpackChunkName: "SimCardActionsSimDetails" */ '@/views/profile/plans/sim-card-actions/sim-details'),
                        beforeEnter: permissions(['is-sim-details-allowed']),
                    },
                    {
                        path: 'number-porting',
                        name: 'number-porting',
                        component: () => import(/* webpackChunkName: "SimCardActionsNumberPorting" */ '@/views/profile/plans/sim-card-actions/number-porting'),
                        beforeEnter: permissions(['is-number-porting-on-dashboard-allowed']),
                    },
                ],
              },
              {
                path: 'security',
                children: [
                    { path: '', name: 'profile-plans-security', beforeEnter: (to, from, next) => next({ name: 'profile-plans-security-basic', replace: false }) },
                ],
              },
            ],
            meta: { profile: 'plans' },
            beforeEnter: permissions(['is-allow-profile-tab']),
          },

          {
            path: 'mobile/security',
            component: () => import(/* webpackChunkName: "Security" */ '@/views/profile/security'),
            children: [
                { path: 'basic', name: 'profile-plans-security-basic', component: () => import(/* webpackChunkName: "SecurityBasic" */ '@/views/profile/security/basic') },
                { path: 'advanced', name: 'profile-plans-security-advanced', component: () => import(/* webpackChunkName: "SecurityAdvanced" */ '@/views/profile/security/advanced') },
                { path: 'reports', name: 'profile-plans-security-reports', component: () => import(/* webpackChunkName: "SecurityReports" */ '@/views/profile/security/reports') },
                { path: 'white-blacklist', name: 'profile-plans-security-white-blacklist', component: () => import(/* webpackChunkName: "SecurityWhiteBlacklist" */ '@/views/profile/security/white-blacklist') },
            ],
            beforeEnter: permissions(['!is-prod']),
          },

          {
            path: 'mobile/usage/details',
            name: 'profile-plans-usage-details',
            component: () => import(/* webpackChunkName: "UsageDetails" */ '@/views/profile/plans/usage/usage-details'),
            meta: { profile: 'plans' },
            beforeEnter: permissions(['is-allow-profile-tab']),
          },

          {
            path: 'my-app-home',
            name: 'profile-my-app-home',
            component: () => import(/* webpackChunkName: "ProfileMyAppHome" */ '@/views/profile/my-app-home'),
            meta: { profile: 'my-app-home' },
            beforeEnter: permissions(['is-allow-profile-tab']),
          },

          {
            path: 'ufb',
            name: 'profile-ufb-home',
            component: () => import(/* webpackChunkName: "UFBProfile" */ '@/views/ufb/profile'),
            meta: { profile: 'ufb' },
            children: [
              { path: '', name: 'profile-ufb', beforeEnter: (to, from, next) => next({ name: 'profile-broadband', replace: false }) },

              {
                path: 'broadband',
                component: () => import(/* webpackChunkName: "ProfileBroadband" */ '@/views/ufb/profile/broadband'),
                meta: { profile: 'profile-broadband' },
                children: [
                  { path: '', name: 'profile-broadband', beforeEnter: (to, from, next) => next({ name: 'profile-broadband-subscriptions', replace: false }) },
                  {
                    path: 'subscriptions',
                    name: 'profile-broadband-subscriptions',
                    component: () => import(/* webpackChunkName: "ProfileBroadbandSubscriptions" */ '@/views/ufb/profile/broadband/subscriptions'),
                  },
                  {
                    path: 'subscribe',
                    name: 'profile-broadband-subscribe',
                    component: () => import(/* webpackChunkName: "ProfileBroadbandSubscribe" */ '@/views/ufb/profile/broadband/subscribe'),
                  },
                  {
                    path: 'usage',
                    name: 'profile-broadband-usage',
                    component: () => import(/* webpackChunkName: "ProfileBroadbandUsage" */ '@/views/ufb/profile/broadband/usage'),
                  },
                ],
              },
              {
                path: 'balance',
                component: () => import(/* webpackChunkName: "Balance" */ '@/views/profile/balance'),
                props: {isUfb: true},
                children: [
                  { path: '',
                    name: 'profile-ufb-balance',
                    beforeEnter: (to, from, next) => next({
                      name: store.getters.is_account_postpay ? 'profile-ufb-balance-pay-bill' : 'profile-ufb-balance-top-up',
                      replace: false
                    })
                  },
    
                  { path: 'pay-bill',
                    name: 'profile-ufb-balance-pay-bill',
                    component: () => import(/* webpackChunkName: "PayBill" */ '@/views/profile/plans/pay-bill'),
                    beforeEnter: permissions(['is-postpay']),
                  },
                  { path: 'top-up',
                    name: 'profile-ufb-balance-top-up',
                    component: () => import(/* webpackChunkName: "TopUp" */ '@/views/profile/plans/top-up'),
                    beforeEnter: permissions(['!is-postpay']),
                  },
                  // { path: 'voucher', name: 'profile-balance-voucher', component: () => import('@/views/profile/balance/voucher') },
    
                  {
                    path: 'payments-history',
                    name: 'profile-ufb-balance-payments-history',
                    component: () => import(/* webpackChunkName: "PaymentsHistory" */ '@/views/profile/balance/payments-history'),
                  },
                  {
                    path: 'payment-methods',
                    name: 'profile-ufb-balance-payment-methods',
                    component: () => import(/* webpackChunkName: "PaymentMethods" */ '@/views/profile/balance/payment-methods'),
                  },
                ],
                meta: { profile: 'balance' },
                beforeEnter: permissions(['is-allow-profile-tab']),
              },
              {
                path: 'bonuses',
                component: () => import(/* webpackChunkName: "Bonuses" */ '@/views/profile/bonuses'),
                props: {isUfb: true},
                children: [
                  { path: '', name: 'profile-ufb-bonuses', beforeEnter: (to, from, next) => next({ name: 'profile-ufb-bonuses-rewards', replace: false }) },
    
                  { path: 'rewards', name: 'profile-ufb-bonuses-rewards', component: () => import(/* webpackChunkName: "Rewards" */ '@/views/profile/bonuses/rewards') },
                  { path: 'gifts', name: 'profile-ufb-bonuses-gifts', component: () => import(/* webpackChunkName: "Gifts" */ '@/views/profile/bonuses/gifts') },
                ],
                meta: { profile: 'bonuses' },
                beforeEnter: permissions(['is-allow-profile-tab']),
              },
              {
                path: 'bonuses/:uuid',
                name: 'profile-ufb-bonuses-view',
                component: () => import(/* webpackChunkName: "BonusView" */ '@/views/profile/bonuses/bonus-view'),
                props: {isUfb: true},
                meta: { profile: 'bonuses' },
                beforeEnter: permissions(['is-allow-profile-tab']),
              },
              {
                path: 'security',
                component: () => import(/* webpackChunkName: "Security" */ '@/views/profile/security'),
                props: {isUfb: true},
                children: [
                  { path: '', name: 'profile-ufb-security', beforeEnter: (to, from, next) => next({ name: 'profile-ufb-security-basic', replace: false }) },
    
                  { path: 'basic', name: 'profile-ufb-security-basic', component: () => import(/* webpackChunkName: "SecurityBasic" */ '@/views/profile/security/basic') },
                  { path: 'advanced', name: 'profile-ufb-security-advanced', component: () => import(/* webpackChunkName: "SecurityAdvanced" */ '@/views/profile/security/advanced') },
                  { path: 'reports', name: 'profile-ufb-security-reports', component: () => import(/* webpackChunkName: "SecurityReports" */ '@/views/profile/security/reports') },
                  { path: 'white-blacklist', name: 'profile-ufb-security-white-blacklist', component: () => import(/* webpackChunkName: "SecurityWhiteBlacklist" */ '@/views/profile/security/white-blacklist') },
                ],
                meta: { profile: 'security' },
                beforeEnter: permissions(['is-allow-profile-tab']),
              },
            ],
            beforeEnter: permissions(['is-allow-profile-tab']),
          },

          {
            path: 'power',
            name: 'profile-power-home',
            component: () => import(/* webpackChunkName: "ProfileUFB" */ '@/views/profile/power'),
            meta: { profile: 'power' },
            beforeEnter: permissions(['is-allow-profile-tab']),
          },

          {
            path: 'my-orders',
            children: [
                { path: '', name: 'profile-my-orders-home', beforeEnter: (to, from, next) => next({ name: 'profile-order-history', replace: false }) },
            ],
            meta: { profile: 'my-orders' },
            beforeEnter: permissions(['is-allow-profile-tab']),
          },

          {
            path: 'offers',
            component: () => import(/* webpackChunkName: "Offers" */ '@/views/profile/offers'),
            children: [
              { path: '', name: 'profile-offers', beforeEnter: (to, from, next) => next({ name: 'profile-offers-all-offers', replace: false }) },

              { path: 'your-offers', name: 'profile-offers-your-offers', component: () => import(/* webpackChunkName: "YourOffers" */ '@/views/profile/offers/your-offers') },
              { path: 'all-offers', name: 'profile-offers-all-offers', component: () => import(/* webpackChunkName: "AllOffers" */ '@/views/profile/offers/all-offers') },
            ],
            meta: { profile: 'offers' },
            beforeEnter: permissions(['is-allow-profile-tab']),
          },
        ],
      },
    ],
    beforeEnter: permissions(['is-account', 'is-account-email-verified', '!is-account-suspended', 'has-payment-cards-or-parent-billing-routes', 'has-addons']),
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "WelcomeLayouts" */ '@/views/layouts'),
    props: { header: false },
    children: [
      { path: '/welcome', name: 'welcome', component: () => import(/* webpackChunkName: "Welcome" */ '@/views/onboarding/welcome') },
      { 
        path: '/billing-routes',
        name: 'welcome-routing',
        component: () => import(/* webpackChunkName: "WelcomeRouting" */ '@/views/onboarding/welcome-routing'),
        beforeEnter: permissions(['is-billing-routing-allowed']),
    },
    //   { path: '/welcome/sign-in', name: 'welcome-sign-in', component: () => import('@/views/onboarding/sign-in') },
    //   { path: '/welcome/sign-up', name: 'welcome-sign-up', component: () => import('@/views/onboarding/sign-up') },
    ],
	// TODO off guard, we log out before enter to welcome page
    // beforeEnter: permissions(['!is-account']),
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "OnboardingLayouts" */ '@/views/layouts'),
    props: { header: false },
    children: [
		{
            path: 'select-account',
            name: 'select-account',
            component: () => import(/* webpackChunkName: "SelectAccount" */ '@/views/select-account'),
            beforeEnter: permissions(['is-auth']),
        },
    {
            path: 'select-ufb-product',
            name: 'select-ufb-product',
            component: () => import(/* webpackChunkName: "SelectAccount" */ '@/views/ufb/profile/select-product'),
            beforeEnter: permissions(['is-auth']),
        },
		{
            path: 'suspended',
            name: 'suspended',
            component: () => import(/* webpackChunkName: "Suspended" */ '@/views/suspended'),
            beforeEnter: permissions(['is-account', 'is-account-suspended']),
        },
		{
            path: 'no-payment-methods',
            name: 'no-payment-methods',
            component: () => import(/* webpackChunkName: "NoPaymentMethods" */ '@/views/no-payment-methods'),
            beforeEnter: permissions(['is-account', '!has-payment-cards-or-parent-billing-routes']),
        },
		{
            path: 'no-plans-page',
            name: 'no-addons-page',
            component: () => import(/* webpackChunkName: "NoPaymentMethods" */ '@/views/no-addons-page'),
            beforeEnter: permissions(['is-account', '!has-addons']),
        },
		{   path: 'onboarding',
            name: 'onboarding', component: () => import(/* webpackChunkName: "Onboarding" */ '@/views/onboarding/flow'),
            // beforeEnter: permissions(['!is-account-type-activated']),
        },
		{ 
            path: 'email-verify-page',
            name: 'email-verify-page',
            component: () => import(/* webpackChunkName: "EmailVerifyPage" */ '@/views/email-verify-page'),
            beforeEnter: permissions(['is-account']),
        },
	],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "OnboardingLayouts" */ '@/views/layouts'),
    props: {
      header: false,
      onboardingHeader: true,
    },
    children: [
        {
            path: 'sim-purchase/:plan?',
            name: 'sim-purchase',
            meta: { keepScrollPosition: true },
            component: () => import(/* webpackChunkName: "SimPurchase" */ '@/views/sim-purchase'),
            beforeEnter: permissions(['is-sim-purchasing-allowed']),
        },
        {
            path: 'sim-select-product',
            name: 'sim-select-product',
            component: () => import(/* webpackChunkName: "SimSelectProduct" */ '@/views/sim-select-product'),
            beforeEnter: permissions(['is-sim-purchasing-allowed']),
        },
        {
            path: 'sim-transfer-product-type',
            name: 'sim-transfer-product-type',
            component: () => import(/* webpackChunkName: "SimTransferProductType" */ '@/views/sim-transfer-product-type'),
            beforeEnter: permissions(['is-account', 'is-account-email-verified', '!is-account-suspended', 'has-payment-cards-or-parent-billing-routes', 'has-addons', 'is-product-type-transfer-allowed']),
        },
	],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "OnboardingLayouts" */ '@/views/layouts'),
    props: { header: false },
    children: [
      {
        path: 'order-placement',
        name: 'order-placement',
        component: () => import(/* webpackChunkName: "OrderPlacement" */ '@/views/order-placement'),
        beforeEnter: permissions(['is-order-placement-allowed']),
      },
    ],
  },
//   {
//     path: '/',
//     component: () => import(/* webpackChunkName: "SimSwapLayouts" */ '@/views/layouts'),
//     props: { header: false },
//     children: [{ path: 'sim-swap', name: 'sim-swap', component: () => import(/* webpackChunkName: "SimSwap" */ '@/views/sim-swap') }],
//     beforeEnter: permissions(['is-account']),
//   },

//   {
//     path: '/',
//     component: () => import(/* webpackChunkName: "MobileAppDeepLinkLayouts" */ '@/views/layouts'),
//     props: { header: false },
//     children: [
//         { path: 'mobile-app-dashboard', name: 'mobile-app-dashboard', component: () => import(/* webpackChunkName: "MobileAppDeepLink" */ '@/views/mobile-app-deep-link') },
// 	],
//   },

  {
    path: '/eshop',
    component: () => import(/* webpackChunkName: "RShopLayouts" */ '@/views/layouts/eshop'),
    children: [
      {
        path: '',
        name: 'eshop',
        component: () => import(/* webpackChunkName: "EShop" */ '@/views/eshop/mobiles'),
        beforeEnter: (to, from, next) => {
            if (store.getters.is_app_environment_prod) {
                next({name: 'eshop-checkout', replace: false})
            } else {
                next()
            }
        },
      },
      { path: 'checkout',         name: 'eshop-checkout',         component: () => import(/* webpackChunkName: "EShopCheckout" */        '@/views/eshop/checkout')                                                       },
      { path: 'checkout-ufb',     name: 'eshop-checkout-ufb',     component: () => import(/* webpackChunkName: "EShopCheckout" */        '@/views/eshop/checkout')                                                       },
      {
        path: 'checkout-landing',
        name: 'eshop-checkout-landing',
        component: () => import(/* webpackChunkName: "EShopCheckoutLanding" */ '@/views/eshop/checkout-landing'),
        beforeEnter: (to, from, next) => {
            if (store.getters.is_app_environment_prod) {
                next({name: 'not-found', replace: false})
            } else {
                next()
            }
        },
      },
      { path: 'email-verify',     name: 'eshop-email-verify',     component: () => import(/* webpackChunkName: "EShopEmailVerify" */     '@/views/eshop/email-verify'),                                                  },
      { path: 'thanks',           name: 'eshop-thanks',           component: () => import(/* webpackChunkName: "EShopThanks" */          '@/views/eshop/thanks'),          beforeEnter: permissions(['eshop-purchased']) },
      {
        path: ':slug',
        name: 'eshop-product',
        component: () => import(/* webpackChunkName: "EShopProduct" */         '@/views/eshop/product'),
        beforeEnter: (to, from, next) => {
            if (store.getters.is_app_environment_prod) {
                next({name: 'not-found', replace: false})
            } else {
                next()
            }
        },
      },
    ],
  },
  ...(process.env.NODE_ENV === 'development' ? [{
      path: '/sandbox',
      component: () => import(/* webpackChunkName: "SandboxLayouts" */ '@/views/layouts'),
      children: [
        { path: '', name: 'sandbox', component: () => import('@/views/sandbox') },
        ...require('@/views/sandbox/routes').default,
      ],
    }] : []
  ),
  {
    path: '*',
	component: () => import(/* webpackChunkName: "NotFoundLayouts" */ '@/views/layouts'),
    props: { header: false },
	redirect: { name: 'not-found' },
    children: [
      { path: '', name: 'not-found', component: () => import(/* webpackChunkName: "NotFound" */ '@/views/not-found') },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  // Scroll up when changing route
  scrollBehavior(to, from, savedPosition) {
    const common_matched = to === from ? [] : to.matched.filter((record) => from.matched.includes(record));
    const keep_scroll_position = common_matched.some((record) => record.meta.keepScrollPosition);
    if (keep_scroll_position) {
      return;
    }

    let scroll = { x: 0, y: 0 };

    if (to.hash) {
      scroll = { selector: to.hash };
    } else if (savedPosition) {
      scroll = savedPosition;
    }

    return scroll;
  },
});

export default router;
