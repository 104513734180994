<template>
    <app-modal class-name="dialog-error"
        ref="app-modal"
        @close-modal="hideError(true)"
        @onenter="hideModal"
    >
        <template v-if="has_header" #header>
            <slot name="header">
                <h3 v-html="title" />
            </slot>
        </template>

        <div>
            <div v-if="has_message" class="error-message">
                <slot>
                    <p v-html="message" />
                </slot>
            </div>
            
            <div class="actions">
                <slot name="actions">
                    <button class="btn" @click="hideModal">{{ button_caption }}</button>
                </slot>
            </div>
        </div>
    </app-modal>
</template>

<script>
import dialog_auto_show from '@/mixins/dialog-auto-show'

export default {
    mixins: [dialog_auto_show],

    props: {
        has_header:     { type: Boolean, default: true                                  },
        has_message:    { type: Boolean, default: true                                  },
        message:        { type: String, default: null                                   },
        title:          { type: String, default() { return `${this.$t('error')}!`      }},
        button_caption: { type: String, default() { return this.$t('ok').toUpperCase() }},
    },

    methods: {
        hideError(is_close_modal = false) {
            if (is_close_modal) {
                this.$emit('closes')
                this.$emit('input', false)
            }
            
            this.$store.commit('hideError')
        },

        hideModalCompleted() {
            this.hideError()
        },
    },
}
</script>

<style lang="scss">
.dialog-error {
    .app-modal__header {
        margin: 0 0 40px;

        h3 {
            margin: 0 0 16px;

            &:last-child { margin: 0; }
        }

        h5 {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .error-message {
        font-size: 24px;
        text-align: center;
    }

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 48px 0 0;

        .btn {
            flex-grow: 1;
            width: auto;
            flex-basis: auto;
            max-width: 220px;
            margin: 0 15px;

            &:first-child { margin-left: 0; }
            &:last-child { margin-right: 0; }
        }
    }
}

@media (max-width: $tablet-size) {
    .dialog-error {
        .app-modal__header {
            margin-bottom: 32px;

            h5 {
                font-size: 20px;
                line-height: 28px;
            }
        }

        .error-message {
            font-size: 18px;
            text-align: center;
        }
    }
}

@media (max-width: $mobile-size) {
    .dialog-error {
        .app-modal__header {
            h5 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .error-message {
            font-size: 16px;
            text-align: center;
        }

        .actions {
            flex-direction: column;
            margin-top: 32px;

            .btn {
                width: 100%;
                max-width: 100%;
                margin: 24px 0 0;

                &:first-child { margin: 0; }
            }
        }
    }
}
</style>