import Vue from 'vue'

import axiosIntents from '@/utils/axios-intents'

import dynStorage from '@/utils/storage-dynamic'

import {
    AVAILABLE_SIM_TYPE_PRODUCTS,
    AVAILABLE_SIM_TYPE_PRODUCTS_BY_UUID,

    eshopItem,

    logAnalyticsItems
} from '@/helpers/eshop'

const ESHOP_V2_CART = 'eshop-v2-cart'
const ESHOP_V2_PURCHASED = 'eshop-v2-purchased'
const ESHOP_V2_CHECKOUT_FLOW = 'eshop-v2-checkout-flow'

const eshopCartItemSim_v2 = (eshop_item, sim, plan) => {
    const item = eshop_item ? {
        UUID: eshop_item.UUID,
        PriceCents: eshop_item.PriceCents,
            Name: eshop_item.Name,
            Type: eshop_item.Type,
            VirtualType: eshop_item.VirtualType,
        ItemUUID: eshop_item.ItemUUID,
        // SelfManaged: eshop_item.SelfManaged, // Don't send SelfManaged in payload
        Quantity: eshop_item.Quantity,

        Attributes: {
            SetupMobileGroup: eshop_item.Attributes?.SetupMobileGroup,
            RequestedForName: eshop_item.Attributes?.RequestedForName,
            MobileGroupOwnerMobileUUID: eshop_item.Attributes?.MobileGroupOwnerMobileUUID,
            SetupBillRouting: eshop_item.Attributes?.SetupBillRouting,
            CustomerCreation: eshop_item.Attributes?.CustomerCreation,
            ReplacesMobileUUID: eshop_item.Attributes?.ReplacesMobileUUID,
            BecomeGroupOwner: eshop_item.Attributes?.BecomeGroupOwner,

            PortingAccountLSP: eshop_item.Attributes?.PortingAccountLSP,
            PortingCarrierLSP: eshop_item.Attributes?.PortingCarrierLSP,
            PortingNametagLSP: eshop_item.Attributes?.PortingNametagLSP,
            PortingPriorSIMID: eshop_item.Attributes?.PortingPriorSIMID,
            PortingOverMSISDN: eshop_item.Attributes?.PortingOverMSISDN,
            PortingOverMSISDNType: eshop_item.Attributes?.PortingOverMSISDNType,
        },

        Products: [
            eshopCartItemProduct_v2(eshop_item.Products[0], plan),
        ],
    } : sim ? {
        UUID: null,
        PriceCents: 0, // PriceCents for SIM comes from BE (for now it is 500 for Physical SIM and 0 for eSIM)
        ...(sim.UUID == AVAILABLE_SIM_TYPE_PRODUCTS.e_sim.UUID ? {
            Name: 'eSIM',
            Type: 'TYPE_VIRTUAL',
            VirtualType: 'VT_ESIM',
        } : {
            Name: 'SIM Card',
            Type: 'TYPE_PRODUCT',
        }),
        ItemUUID: sim.UUID,
        SelfManaged: sim.SelfManaged === true,

        Attributes: {
            SetupMobileGroup: sim.Grouped,
            RequestedForName: sim.GroupMemberName,
            MobileGroupOwnerMobileUUID: sim.MobileGroupOwnerMobileUUID,
            SetupBillRouting: sim.UseMasterBillingAccount,
            CustomerCreation: sim.CustomerCreation,
            BecomeGroupOwner: sim.BecomeGroupOwner,
        },

        Products: [
            eshopCartItemProduct_v2(null, plan),
        ],
    } : null

    if (item.Products[0] == null) {
        item.Products.length = 0
    }

    // item.SPID = store.getters.app_spid
    // item.Quantity = 1
    item.Description = item.Name

    return item
}

const eshopCartItemProduct_v2 = (eshop_item, plan) => {
    const item = eshop_item ? {
        UUID: eshop_item.UUID,
        Name: eshop_item.Name,
        Description: eshop_item.Description,
        PriceCents: eshop_item.PriceCents,
        PriceCatalogCents: eshop_item.PriceCatalogCents,
        Type: eshop_item.Type,
        VirtualType: eshop_item.VirtualType,
        Quantity: eshop_item.Quantity,
        Attributes: {
            BillingReference: eshop_item.Attributes.BillingReference,
            MobileProductAvailabilityUUID: eshop_item.Attributes.MobileProductAvailabilityUUID,
            MobileProductCatalogUUID: eshop_item.Attributes.MobileProductCatalogUUID,
            AutoRenew: eshop_item.Attributes.AutoRenew,
            DiscountUUID: eshop_item.Attributes.DiscountUUID,
        },
    } : plan ? {
        UUID: null,
        Name: plan.merged_name
            || `${ plan.Availabilities[0].RetailName } (${
                plan.is_payg
                    ? 'Payg'
                    : plan.ProductType == 'postpaymobile'
                        ? 'Postpay'
                        : process.env.VUE_APP_ESHOP_CART_ITEM_PREPAY
            })`,
        Description: plan.Availabilities[0].RetailDescription,
        PriceCents: plan.Availabilities[0].RetailCents,
        PriceCatalogCents: plan.price_catalog_cents,
        Type: 'TYPE_VIRTUAL',
        VirtualType: 'VT_PLAN',
        Attributes: {
            BillingReference: plan.is_payg
                ? 'payg'
                : plan.ProductType == 'postpaymobile'
                    ? 'postpay'
                    : 'prepay',
            MobileProductAvailabilityUUID: plan.Availabilities[0].UUID,
            MobileProductCatalogUUID: plan.Availabilities[0].ProductCatalogEntryUUID,
            AutoRenew: Boolean(plan.is_autoRenew),
            DiscountUUID: plan.DiscountUUID,
        },
    } : null

    // item.SPID = store.getters.app_spid
    // item.Quantity = 1
    // item.Description = 

    return item
}

const eshopCartItemsMultiPlansSelfManaged_v2 = ({ items, is_mobile_group_sim_order = false } = {}) => {
    const count = items.length

    if (items) {
        for (let i = 0; i < count; i++) {
            if (items[i].SelfManaged) {
                items[i].SelfManaged = false
                items[i].Attributes.CustomerCreation = process.env.VUE_APP_ORDER_CUSTOMER_CREATION_WITH_SELF_MANAGE_TOGGLE_ON
            } else {
                items[i].Attributes.CustomerCreation = 'BrandNewCustomer'
            }
        }

        // Всегда устанавливаем SelfManaged для 1й SIM-ки
        // Если не is_mobile_group_sim_order
        // commented under the terms of RSL-1043 task 
        /* if (!is_mobile_group_sim_order) {
            items[0].SelfManaged = true
        } */
    }

    return items
}

export default {
    state: {
        eshop_v2_cart: {},
        eshop_v2_cart_loading: false,

        eshop_v2_purchased: null,

        eshop_v2_checkout_flows: {},
    },
    
    getters: {
        eshop_v2_cart: state => state.eshop_v2_cart,
        eshop_v2_cart_uuid: state => state.eshop_v2_cart.UUID,
        eshop_v2_cart_number: state => state.eshop_v2_cart.Number,

        eshop_v2_cart_sims: state => state.eshop_v2_cart.Items?.filter(item => item.ItemUUID in AVAILABLE_SIM_TYPE_PRODUCTS_BY_UUID) || [],

        eshop_v2_cart_loading: state => state.eshop_v2_cart_loading,

        eshop_v2_cart_items: state => state.eshop_v2_cart.Items?.length
            ? state.eshop_v2_cart.Items.reduce((acc, eshop_item) => {
                /** @text */
                const specifications = [
                    eshop_item.VirtualType == 'VT_ESIM' ? 'eSIM' : 'Physical SIM',
                    (!(eshop_item?.Products?.[0]?.Attributes?.AutoRenew) ? 'Non ' : '') + 'Auto Renew',
                ]
                if (eshop_item.Attributes?.CustomerCreation === process.env.VUE_APP_ORDER_CUSTOMER_CREATION_WITH_SELF_MANAGE_TOGGLE_ON) { specifications.push('Self Manage') }
                if (eshop_item.Grouped || eshop_item.Attributes?.SetupMobileGroup) { specifications.push('Added To The Group') }
                if (eshop_item.UseMasterBillingAccount || eshop_item.Attributes?.SetupBillRouting) { specifications.push('Charged To Me') }

                if (eshop_item.Products?.[0]) {
                    acc.push(eshopItem(eshop_item, { UUID: eshop_item.UUID, name_counted: `SIM Card ${ (acc.length / 2) + 1 }`, specifications }))
                    acc.push(eshopItem(eshop_item.Products[0], { UUID: eshop_item.Products[0].UUID }))
                } else {
                    acc.push(eshopItem(eshop_item, { UUID: eshop_item.UUID, name_counted: 'SIM Card', specifications }))
                }
                return acc
            }, [])
            : [],
        eshop_v2_cart_items_count: (state, getters) => getters.eshop_v2_cart_items.length,

        eshop_v2_cart_total: (state, getters) => {
            let price = 0
            let discount = 0

            const eshop_v2_cart_items = getters.eshop_v2_cart_items

            for (let i = 0, len = eshop_v2_cart_items.length; i < len; i++) {
                if (eshop_v2_cart_items[i]) {
                    price+= eshop_v2_cart_items[i].discount ? eshop_v2_cart_items[i].discount : eshop_v2_cart_items[i].price
                    discount+= eshop_v2_cart_items[i].discount ? eshop_v2_cart_items[i].price : 0
                }
            }

            return price && discount ? {
                price: discount,
                discount: price,
            } : {
                price,
                discount: null,
            }
        },

        eshop_v2_cart_SIMs_total: (state, getters) => {
            let price = 0

            const eshop_v2_cart_items = getters.eshop_v2_cart_items

            for (let i = 0, len = eshop_v2_cart_items.length; i < len; i++) {
                if (eshop_v2_cart_items[i] && (eshop_v2_cart_items[i].type == "sim" || eshop_v2_cart_items[i].type == "esim")) {
                    price+= eshop_v2_cart_items[i].price
                }
            }

            return price
        },

        eshop_v2_cart_items_quantity: (state, getters) => getters.eshop_v2_cart_items.reduce((quantity, item) => quantity+= item?.quantity || 0, 0),

        eshop_v2_purchased: state => state.eshop_v2_purchased,

        eshop_v2_has_e_sim: state => state.eshop_v2_cart.Items?.findIndex(item => item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.e_sim.UUID) > -1,
        eshop_v2_has_only_e_sims: state => state.eshop_v2_cart.Items?.length && state.eshop_v2_cart.Items?.every(item => item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.e_sim.UUID),
        eshop_v2_has_real_sim: state => state.eshop_v2_cart.Items?.findIndex(item => item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.real_sim.UUID) > -1,

        eshop_v2_checkout_flows: state => state.eshop_v2_checkout_flows,

        eshop_v2_with_input_delivery_instructions: () => process.env.VUE_APP_INPUT_DELIVERY_INSTRUCTIONS == 'true',
        eshop_v2_with_input_delivery_contact_number: () => process.env.VUE_APP_INPUT_DELIVERY_CONTACT_NUMBER == 'true',
        eshop_v2_sims_to_port: (state, getters) =>  {
            return getters.eshop_v2_cart_sims
                .map((sim, index) => {
                    return {
                        ...sim,
                        sim_counted_name: `SIM_Card ${index + 1}`
                    }
                })
                .filter(sim => sim.Attributes?.PortingOverMSISDN)
                .map(sim_to_port => {
                    const eshop_v2_cart_uuid = getters.eshop_v2_cart_uuid
                    const UUID = sim_to_port.UUID

                    const SIMS_PORTING_TYPES = dynStorage.get('SIMS_PORTING_TYPES', true)

                    const SIM_PORTING_TYPE = SIMS_PORTING_TYPES?.[`${eshop_v2_cart_uuid}--${UUID}`] || ''
                    
                    return {
                        sim_name: sim_to_port.sim_counted_name,
                        existing_phone_number: sim_to_port.Attributes?.PortingOverMSISDN,
                        existing_phone_type: sim_to_port.Attributes?.PortingOverMSISDNType || SIM_PORTING_TYPE,
                        UUID: sim_to_port.UUID,
                        local_storage_sims_porting_types_key: `${getters.eshop_v2_cart_uuid}--${ sim_to_port.UUID}`,
                    }
                })
        },

        eshop_v2_has_postpay_product: (state, getters) => getters.eshop_v2_cart_items?.some(item => item.product?.name?.endsWith('(Postpay)')),
    },

    mutations: {
        eshopCartSet_v2(state, eshop_cart) {
            state.eshop_v2_cart = eshop_cart

            if (eshop_cart.CustomerUUID || !eshop_cart.UUID) {
                dynStorage.remove(ESHOP_V2_CART)
            } else {
                dynStorage.set(ESHOP_V2_CART, eshop_cart.UUID, false, true)
            }
        },



        eshopCartLoading_v2(state, loading) {
            state.eshop_v2_cart_loading = loading
        },

        eshopCartReset_v2(state, force) {
            if (!state.eshop_v2_cart.UUID || force) {
                state.eshop_v2_cart = {}
            }
        },



        eshopPurchasedSave_v2(state, { payload, location, is_only_sim }) {
            const e_sim = payload.Items.findIndex(item => item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.e_sim.UUID) > -1
            const real_sim = payload.Items.findIndex(item => item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.real_sim.UUID) > -1

            let eshop_v2_purchased = {
                e_sim,
                number: payload.Number,
            }

            /**
             * @todo сделать проверку на наличие "физических" товаров в корзине,
             *       когда они появятся
             */
            if (real_sim) {
                eshop_v2_purchased.address = location?.Description
                eshop_v2_purchased.is_only_sim = is_only_sim
            }

            state.eshop_v2_purchased = eshop_v2_purchased

            dynStorage.set(ESHOP_V2_PURCHASED, eshop_v2_purchased, false, true)
        },

        eshopPurchasedLoad_v2(state) {
            state.eshop_v2_purchased = dynStorage.get(ESHOP_V2_PURCHASED, true)
        },

        eshopPurchasedReset_v2(state) {
            state.eshop_v2_purchased = null

            dynStorage.remove(ESHOP_V2_PURCHASED)
        },



        eshopCheckoutFlowSave_v2(state, flow) {
            Vue.set(state.eshop_v2_checkout_flows, flow.id, flow)
            dynStorage.set(ESHOP_V2_CHECKOUT_FLOW, state.eshop_v2_checkout_flows, false, true)
        },
        eshopCheckoutFlowReset_v2(state, flow_id) {
            Vue.delete(state.eshop_v2_checkout_flows, flow_id)

            if (Object.keys(state.eshop_v2_checkout_flows).length) {
                dynStorage.set(ESHOP_V2_CHECKOUT_FLOW, state.eshop_v2_checkout_flows, false, true)
            } else {
                dynStorage.remove(ESHOP_V2_CHECKOUT_FLOW)
            }
        },

        
        eshopCheckoutFlowsLoad_v2(state) {
            state.eshop_v2_checkout_flows = dynStorage.get(ESHOP_V2_CHECKOUT_FLOW, true) || {}
        },
        eshopCheckoutFlowsReset_v2(state) {
            state.eshop_v2_checkout_flows = {}
            dynStorage.remove(ESHOP_V2_CHECKOUT_FLOW)
        },
    },

    actions: {
        eshopCartLoad_v2({getters, commit, dispatch}) {
            commit('eshopCartLoading_v2', true)

            let cart_uuid = dynStorage.get(ESHOP_V2_CART, true)

            if (cart_uuid == "undefined") {
                cart_uuid = null

                dynStorage.remove(ESHOP_V2_CART)
            }

            return dispatch('GetCatalogServices').then(() =>
                (cart_uuid
                    ? dispatch('eshopCartRestoreByUUID_v2', cart_uuid)
                    : getters.current_account_uid
                        ? dispatch('eshopCartRestoreByCustomer_v2')
                        : Promise.resolve(getters.eshop_v2_cart)
                ).finally(() => {
                    commit('eshopCartLoading_v2', false)
                })
            )
        },

        eshopCartSaveSIMs_v2({getters, commit, dispatch}, sims) {
            if (!sims) {
                const getters_eshop_v2_cart_sims_modified = [...getters.eshop_v2_cart_sims]

                getters_eshop_v2_cart_sims_modified.forEach(cart_sim => {
                    const cart_sim_item_product_catalog_uuid = cart_sim.Products?.[0]?.Attributes?.MobileProductCatalogUUID
                    const cart_sim_item_product_availability_uuid = cart_sim.Products?.[0]?.Attributes?.MobileProductAvailabilityUUID

                    const cart_sim_plan_product = cart_sim_item_product_catalog_uuid && cart_sim_item_product_availability_uuid
                        ? getters.all_services.find(item =>
                            item.UUID == cart_sim_item_product_catalog_uuid
                            && item.Availabilities?.[0]?.UUID == cart_sim_item_product_availability_uuid
                        )
                        : null

                    const cart_sim_plan_product_retailCents = cart_sim_plan_product?.Availabilities?.[0]?.RetailCents

                    if (cart_sim_plan_product_retailCents != undefined) {
                        cart_sim.Products[0].PriceCents = cart_sim_plan_product_retailCents
                    }
                })

                sims = getters_eshop_v2_cart_sims_modified
            }

            const payload = {
                SPID: getters.app_spid,
                UUID: getters.eshop_v2_cart_uuid,
                Number: getters.eshop_v2_cart_number,
                CustomerUUID: getters.current_account_uid,

                Items: sims.map(eshop_item => eshopCartItemSim_v2(eshop_item)),
            }

            return axiosIntents.post('/eshop/v2/cart', payload).then(({result}) => {
                commit('eshopCartSet_v2', result)

                if (result?.Items) {
                    const value = ((result.AmountCents || 0) + (result.AmountTaxCents || 0)) / 100

                    dispatch('logAnalyticsEventAddToCart', { parameters: {
                        currency: "NZD",
                        value,
                        items: logAnalyticsItems(result?.Items),
                    }})
                }

                return Promise.resolve(getters.eshop_v2_cart)
            })
        },

        eshopCartSaveSIMsMultiPlans_v2({getters, dispatch}, { sims_multi_plans, is_mobile_group_sim_order = false } = {}) {
            return dispatch('GetCatalogServices').then(() => {
                try {
                    const sims = []

                    for (let i = 0, len = sims_multi_plans.length; i < len; i++) {
                        const product_catalog_uuid = sims_multi_plans[i]?.Product?.MobileProductCatalogUUID
                        const product_availability_uuid = sims_multi_plans[i]?.Product?.MobileProductAvailabilityUUID

                        if (product_catalog_uuid && product_availability_uuid) {
                            const product = getters.all_services.find(item =>
                                item.UUID == product_catalog_uuid
                                && item.Availabilities?.[0]?.UUID == product_availability_uuid
                            )

                            if (product) {
                                const sim = {...AVAILABLE_SIM_TYPE_PRODUCTS_BY_UUID[sims_multi_plans[i].ItemUUID]}

                                if (sim) {
                                    sim.SelfManaged = sims_multi_plans[i].SelfManaged
                                    sim.Grouped = sims_multi_plans[i].Grouped
                                    sim.GroupMemberName = sims_multi_plans[i].GroupMemberName
                                    sim.UseMasterBillingAccount = sims_multi_plans[i].UseMasterBillingAccount
                                    sim.MobileGroupOwnerMobileUUID = sims_multi_plans[i].MobileGroupOwnerMobileUUID
                                    sim.BecomeGroupOwner = sims_multi_plans[i].BecomeGroupOwner

                                    const plan = {
                                        ...product,
                                        ProductType: sims_multi_plans[i].Product.ProductType,
                                        is_payg: sims_multi_plans[i].Product.is_payg,
                                        is_autoRenew: sims_multi_plans[i].Product.is_autoRenew,
                                    }

                                    sims.push(eshopCartItemSim_v2(null, sim, plan))
                                } else {
                                    throw new Error(`SIM ${ i+1 } type is not available`)
                                }
                            } else {
                                throw new Error(`SIM ${ i+1 } plan is not available`)
                            }
                        } else {
                            throw new Error(`SIM ${ i+1 } plan is not specified`)
                        }
                    }

                    return dispatch('eshopCartSaveSIMs_v2', eshopCartItemsMultiPlansSelfManaged_v2({ items: sims, is_mobile_group_sim_order }))
                } catch (error) {
                    return Promise.reject(error)
                }
            })
        },

        eshopCartAddSIM_v2({getters, dispatch}, { type, plan, empty, replacement_sim_options }) {
            if (type in AVAILABLE_SIM_TYPE_PRODUCTS) {
                const sims = getters.eshop_v2_cart_sims

                sims.push(eshopCartItemSim_v2(null, AVAILABLE_SIM_TYPE_PRODUCTS[type], plan?.product))

                if (empty) {
                    sims.push(eshopCartItemSim_v2(null, AVAILABLE_SIM_TYPE_PRODUCTS[type], null))
                }

                const is_only_sim = sims.length == 1 && sims[0]?.Products.length == 0

                if (replacement_sim_options &&  is_only_sim) {
                    const eshop_item = sims[0]

                    eshop_item.Attributes = {
                        ...(eshop_item.Attributes && eshop_item.Attributes),
                        SetupMobileGroup: replacement_sim_options.setup_mobile_group,
                        RequestedForName: replacement_sim_options.name,
                        SetupBillRouting: Boolean(replacement_sim_options.billing_child_route_uuid),
                        ReplacesMobileUUID: replacement_sim_options.mobile_product_uuid,
                    }
                }

                return dispatch('eshopCartSaveSIMs_v2', sims)
            } else {
                return Promise.reject('Unknown SIM type')
            }
        },

        async eshopCartSetSIMPlan_v2({getters, dispatch}, { type, plan, sim_uuid }) {
            if (type in AVAILABLE_SIM_TYPE_PRODUCTS) {
                if (plan?.product?.Availabilities
                    && Array.isArray(plan.product.Availabilities)
                    && plan.product.Availabilities.length
                ) {
                    const sims = getters.eshop_v2_cart_sims

                    const has_only_sim_in_sims = Boolean(sims.length) && !sims[0]?.Products.length

                    if (has_only_sim_in_sims) {
                        try {
                            await dispatch('eshopCartRemoveAllSIMs_v2')
                        } catch (error) {
                            return Promise.reject(error)
                        }
                    }

                    if (sim_uuid && sims.length) {
                        let is_changed = false

                        const updateSIMPlan = (sim) => {
                            if (sim
                                && (sim.Products[0]?.ProductCatalogUUID != plan.product.UUID
                                    || sim.Products[0]?.Attributes?.AutoRenew != plan.product.is_autoRenew
                                )
                            ) {
                                is_changed = true

                                sim.Products = [
                                    eshopCartItemProduct_v2(null, plan.product)
                                ]
                            }
                        }

                        if (sim_uuid == 'all') {
                            for (let i = 0, len = sims.length; i < len; i++) {
                                updateSIMPlan(sims[i])
                            }
                        } else {
                            const index = sims.findIndex(sim => sim.UUID == sim_uuid)

                            if (index > -1) {
                                updateSIMPlan(sims[index])
                            }
                        }

                        if (is_changed) {
                            return dispatch('eshopCartSaveSIMs_v2', sims)
                        } else {
                            return Promise.resolve(sims)
                        }
                    } else {
                        return dispatch('eshopCartAddSIM_v2', { type, plan })
                    }
                } else {
                    return Promise.reject(`Plan not specified`)
                }
            } else {
                return Promise.reject('Unknown SIM type')
            }
        },

        eshopCartRemoveSIM_v2({getters, dispatch}, uuid) {
            const sims = [...getters.eshop_v2_cart_sims]
            const index = sims.findIndex(sim => sim.UUID == uuid)

            if (index > -1) {
                sims.splice(index, 1)

                return dispatch('eshopCartSaveSIMs_v2', sims)
            } else {
                return Promise.resolve(uuid)
            }
        },

        eshopCartRemoveAllSIMs_v2({dispatch}) {
            return dispatch('eshopCartSaveSIMs_v2', [])
        },

        eshopCartRestoreByUUID_v2({getters, commit}, uuid) {
            return axiosIntents.get(`/eshop/v2/cart/${ uuid }`).then(({result}) => {
                commit('eshopCartSet_v2', result)

                return Promise.resolve(getters.eshop_v2_cart)
            })
        },

        eshopCartRestoreByCustomer_v2({getters, commit}) {
            const uuid = getters.current_account_uid

            return axiosIntents.get('/eshop/v2/customer/:uuid/cart').then(({result}) => {
                if (uuid == getters.current_account_uid) {
                    commit('eshopCartSet_v2', result)
                }

                return Promise.resolve(getters.eshop_v2_cart)
            })
        },

        eshopPurchase_v2({getters, commit, dispatch}, { location, is_only_sim, payment, delivery_instructions, delivery_contact_number }) {
            const sims = getters.eshop_v2_cart_sims

            const local_storage_sims_porting_types_keys = getters.eshop_v2_sims_to_port.map(sim_to_port => sim_to_port.local_storage_sims_porting_types_key)

            const auth_user_profile_creds = getters.auth_user_profile_creds

            const payload = {
                SPID: getters.app_spid,
                UUID: getters.eshop_v2_cart_uuid,
                Number: getters.eshop_v2_cart_number,
                CustomerUUID: getters.current_account_uid,

                Address: {
                    City: location?.City,
                    Country: location?.Country,
                    Postcode: location?.PostCode,
                    Lines: location?.Lines,
                    LocationProvider: location?.LocationProvider,
                    LocationProviderIDX: location?.LocationProviderIDX,
                    Email: auth_user_profile_creds.email,
                    Name: [auth_user_profile_creds.first_name, auth_user_profile_creds.last_name].join(' ').trim(),
                    Notes: delivery_instructions,
                    Phone: getters.eshop_v2_with_input_delivery_contact_number
                        ? delivery_contact_number
                        : auth_user_profile_creds.phone,
                },

                Items: sims.map(eshop_item => eshopCartItemSim_v2(eshop_item)),
            }

            return axiosIntents.post('/eshop/v2/checkout', payload).then(({result}) => {
                if (getters.is_checkout_payment_confirmation && result?.ClientSecret) {

                    const srtripe_client_secret = result.ClientSecret

                    return dispatch('stripePaymentConfirmation', {
                        card_token: payment?.cardId || getters.defaultPaymentCardId,
                        client_secret: srtripe_client_secret,
                    }).catch(error => {
                        return Promise.reject({ ...error, srtripe_client_secret, eshopPurchasedSave_data: { payload, location, is_only_sim } })
                    })
                }

                return Promise.resolve(result)
            }).then(result => {
                commit('eshopPurchasedSave_v2', { payload, location, is_only_sim })

                if (local_storage_sims_porting_types_keys.length) {
                    const SIMS_PORTING_TYPES = dynStorage.get('SIMS_PORTING_TYPES', true)
    
                    if (SIMS_PORTING_TYPES) {
                        local_storage_sims_porting_types_keys.forEach(key => {
                            delete SIMS_PORTING_TYPES[key]
                        })
    
                        dynStorage.set('SIMS_PORTING_TYPES', SIMS_PORTING_TYPES, false, true)
                    }
                }

                if (payload.Items.length) {
                    const items = logAnalyticsItems(payload.Items)
                    const value = items.reduce((value, item) => value+= (item.price || 0) * (item.quantity || 0), 0)
    
                    dispatch('logAnalyticsEventPurchase', { parameters: {
                        currency: "NZD",
                        value,
                        items,
                    }})
                }

                return Promise.resolve(result)
            })
        },



        eshopGetCustomerOrders_v2() {
            return axiosIntents.get('/eshop/v2/customer/:uuid/orders')
                .then(({result}) => Promise.resolve(result))
        },

        eshopGetCustomerOrder_v2({}, OrderNumber) {
            return axiosIntents.get(`/eshop/v2/customer/:uuid/order/${OrderNumber}`)
                .then(({result}) => Promise.resolve(result))
        },

        eshopGetCustomerOrderItem_v2({ dispatch }, { OrderNumber, UUID }) {
            return dispatch('eshopGetCustomerOrder_v2', OrderNumber).then(({OrderItems, Status}) => {
                if (!OrderItems) return Promise.reject('No Order Items')

                const spreded_order_items = OrderItems.reduce((acc, orderItem) => {
                    const order_item_products = orderItem.Products || []

                    return [...acc, orderItem, ...order_item_products]
                }, [])

                const order_item = spreded_order_items.find(item => item.UUID == UUID)

                if (!order_item) return Promise.reject('No Order Item')

                return Promise.resolve({...order_item, Status})
            })
        },

        eshopCartAddNumberPortingToSIM_v2({getters, dispatch}, {
            UUID,
            PortingAccountLSP,
            PortingCarrierLSP,
            PortingNametagLSP,
            PortingPriorSIMID,
            PortingOverMSISDN,
            PortingOverMSISDNType
        } = {}) {
            const sims = [...getters.eshop_v2_cart_sims]
            const index = sims.findIndex(sim => sim.UUID == UUID)

            if (index > -1) {
                const eshop_v2_cart_uuid = getters.eshop_v2_cart_uuid

                let SIMS_PORTING_TYPES = dynStorage.get('SIMS_PORTING_TYPES', true)

                if (SIMS_PORTING_TYPES) {
                    SIMS_PORTING_TYPES[`${eshop_v2_cart_uuid}--${UUID}`] = PortingOverMSISDNType
                } else {
                    SIMS_PORTING_TYPES = {}
                    SIMS_PORTING_TYPES[`${eshop_v2_cart_uuid}--${UUID}`] = PortingOverMSISDNType
                }

                dynStorage.set('SIMS_PORTING_TYPES', SIMS_PORTING_TYPES, false, true)
                
                sims[index] = {
                    ...sims[index],
                    Attributes: {
                        ...(sims[index].Attributes && sims[index].Attributes),
                        PortingAccountLSP,
                        PortingCarrierLSP,
                        PortingNametagLSP,
                        PortingPriorSIMID,
                        PortingOverMSISDN,
                        PortingOverMSISDNType,
                    }
                }

                return dispatch('eshopCartSaveSIMs_v2', sims)
            } else {
                return Promise.resolve(UUID)
            }
        },

        eshopCartRemoveNumberPortingFromSIM_v2({getters, dispatch}, UUID) {
            const sims = [...getters.eshop_v2_cart_sims]
            const index = sims.findIndex(sim => sim.UUID == UUID)

            if (index > -1) {
                const Attributes = {
                    ...(sims[index].Attributes && sims[index].Attributes)
                }

                Attributes.PortingAccountLSP = ''
                Attributes.PortingCarrierLSP = 0
                Attributes.PortingNametagLSP = ''
                Attributes.PortingPriorSIMID = ''
                Attributes.PortingOverMSISDN = ''
                Attributes.PortingOverMSISDNType = ''

                sims[index] = {
                    ...sims[index],
                    Attributes,
                }

                return dispatch('eshopCartSaveSIMs_v2', sims).then(saved_sims => {
                    const eshop_v2_cart_uuid = getters.eshop_v2_cart_uuid
                    const SIMS_PORTING_TYPES = dynStorage.get('SIMS_PORTING_TYPES', true)

                    if (SIMS_PORTING_TYPES) {
                        delete SIMS_PORTING_TYPES[`${eshop_v2_cart_uuid}--${UUID}`]

                        dynStorage.set('SIMS_PORTING_TYPES', SIMS_PORTING_TYPES, false, true)
                    }

                    return Promise.resolve(saved_sims)
                })
            } else {
                return Promise.resolve(UUID)
            }
        },
    },
}