import Vue from 'vue'
import axiosIntents from '@/utils/axios-intents'

import StoreHash from '@/utils/StoreHash'

const cashed = {
    sim_settings: {
        uuids: {},
        lifetime: 5 * 60 * 1000, // 5 minutes
    }
}

const requests = {}

const state = {
    sim_settings: {},
}

const getters = {
    sim_settings: (state, getters) => state.sim_settings.hasOwnProperty(getters.customer_current_product_uuid)
        ? state.sim_settings[getters.customer_current_product_uuid]
        : {},
}

const mutations = {
    setSIMSettings(state, { settings, MobileUUID }) {
        Vue.set(state.sim_settings, MobileUUID, settings)

        if (!cashed.sim_settings.uuids.hasOwnProperty(MobileUUID)) {
            cashed.sim_settings.uuids[MobileUUID] = new StoreHash(cashed.sim_settings.lifetime)
        }
        cashed.sim_settings.uuids[MobileUUID].fix()
    },

    RESET_SIM_SETTINGS(state) {
        state.sim_settings = {}

        cashed.sim_settings.uuids = {}

        for (const MobileUUID in requests) {
            delete requests[MobileUUID]
        }
    },
}

const actions = {
    getSIMSettings({getters, commit, dispatch}, force) {
        const MobileUUID = getters.customer_current_product_uuid

        if (requests.hasOwnProperty(MobileUUID)) {
            return requests[MobileUUID].type === 'get'
                ? requests[MobileUUID].request
                : requests[MobileUUID].request.catch(() => dispatch('getSIMSettings', force))
        } else if (force || !cashed.sim_settings.uuids[MobileUUID] || cashed.sim_settings.uuids[MobileUUID].expired()) {
            const params = {
                MobileUUID,
                CustomerUUID: getters.current_account_uid,
            }

            const request = axiosIntents.get('/sim/v1/settings', { params }).then(({data: settings}) => {
                if (MobileUUID === getters.customer_current_product_uuid) {
                    commit('setSIMSettings', { settings, MobileUUID })
                }

                return Promise.resolve(getters.sim_settings)
            }).finally(() => {
                if (requests.hasOwnProperty(MobileUUID)
                    && requests[MobileUUID].type === 'get'
                ) {
                    delete requests[MobileUUID]
                }
            })

            requests[MobileUUID] = {
                type: 'get',
                request,
            }

            return request
        } else {
            return Promise.resolve(getters.sim_settings)
        }
    },

    updateSIMSettings({getters, commit, dispatch}, settings) {
        const MobileUUID = getters.customer_current_product_uuid

        /**
         * getSIMSettings необходим для актуализации sim_settings перед их изменением
         * так как API не позволяет изменять только указанные пользователем settings
         * из-за чего могут быть некорректно установлены другие settings
         */
        const request_id = requests.hasOwnProperty(MobileUUID)
            && requests[MobileUUID].type === 'put'
                ? requests[MobileUUID].request_id + 1
                : 1

        const request = (
            requests.hasOwnProperty(MobileUUID)
                ? requests[MobileUUID].type === 'put'
                    ? requests[MobileUUID].request.catch(() => dispatch('getSIMSettings', true))
                    : requests[MobileUUID].request
                : dispatch('getSIMSettings', true)
        ).then(sim_settings => {
            let is_updated = true

            if (MobileUUID === getters.customer_current_product_uuid) {
                for (const group in sim_settings) {
                    if (!settings.hasOwnProperty(group)) {
                        settings[group] = {}
                    }

                    for (const key in sim_settings[group]) {
                        if (settings[group].hasOwnProperty(key)) {
                            if (is_updated && settings[group][key] !== sim_settings[group][key]) {
                                is_updated = false
                            }
                        } else {
                            settings[group][key] = sim_settings[group][key]
                        }
                    }
                }
            }

            return is_updated
                ? Promise.resolve(getters.sim_settings)
                : axiosIntents.put('/sim/v1/settings', {
                    MobileUUID,
                    CustomerUUID: getters.current_account_uid,
                    Settings: settings,
                }).then(({data: settings}) => {
                    if (MobileUUID === getters.customer_current_product_uuid) {
                        commit('setSIMSettings', { settings, MobileUUID })
                    }

                    return Promise.resolve(getters.sim_settings)
                })
        }).finally(() => {
            if (requests.hasOwnProperty(MobileUUID)
                && requests[MobileUUID].type === 'put'
                && requests[MobileUUID].request_id === request_id
            ) {
                delete requests[MobileUUID]
            }
        })

        requests[MobileUUID] = {
            type: 'put',
            request,
            request_id,
        }

        return request
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
