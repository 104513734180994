import axiosIntents from '@/utils/axios-intents'

import i18n from '@/i18n'

import dynStorage from '@/utils/storage-dynamic';

export default {
    state: {
        sim: {},
        is_sim_enabled: true,
    },

    getters: {
        sim: state => state.sim,
        is_sim_purchasing_allowed: (state, getters) => {
            return ['true', 'yes', 1].includes(getters.getAppEnvParam('IS_SIM_PURCHASING_ALLOWED').toLowerCase())
        },
        has_sim_type_to_purchase: (state) => Boolean(state.sim?.sim_type),
        has_product_in_sim_to_purchase: (state) => Boolean(state.sim?.product),
        is_sim_enabled: state => state.is_sim_enabled,
        is_only_physical_sim_menu_item_present: (state, getters) => (getters.is_auth && !getters.is_app_environment_enabled_esim_menu_item) || (!getters.is_auth && !getters.is_app_environment_enabled_i_already_have_sim_menu_item && !getters.is_app_environment_enabled_esim_menu_item),
    },

    actions: {
        buySim({}, card_token) {
            return new Promise((resolve, reject) => {
			    setTimeout(() => {
				    resolve(card_token)
				    // reject({ message: 'Network Error' })
			    }, 2000);
            })
        },

        changeSimEnabledStatus({ getters, commit }, is_sim_to_enable) {
            const CustomerUUID = getters.current_account_uid
            const ProductInstanceUUID = getters.customer_current_product_uuid
            const changeSimEnabledStatusURL = is_sim_to_enable ? '/sim/enable' : '/sim/disable'
            const Type = getters.is_account_postpay ? 'postpay' : 'prepay'

            if (!CustomerUUID || !ProductInstanceUUID) return Promise.reject(`${i18n.t('error_occurred')}. ${i18n.t('try_again_later')}`)

            return axiosIntents.post(changeSimEnabledStatusURL, { CustomerUUID, ProductInstanceUUID, Type })
                .then(() => {
                    commit('SET_SIM_ENABLED_STATUS', is_sim_to_enable)

                    return Promise.resolve(is_sim_to_enable)
                }).catch(error => {
                    console.log('changeSimEnabledStatus Error:', error)

                    return Promise.reject(`${i18n.t('error_occurred')}. ${i18n.t('try_again_later')}`)
                })
        },

        simSwap({ getters }, { SIMID  } = {}) {
            const CustomerUUID = getters.current_account_uid
            const ProductInstanceUUID = getters.customer_current_product_uuid

            if (!CustomerUUID || !ProductInstanceUUID) return Promise.reject(`${i18n.t('error_occurred')}. ${i18n.t('try_again_later')}`)

            return axiosIntents.post('/sim/swap',
				{
                    CustomerUUID,
                    ProductInstanceUUID,
                    SIMID,
				}
			)
            .then(({result}) =>  Promise.resolve(result))
            .catch(error => {
                console.log('simSwap Error:', error)

                return Promise.reject(i18n.t('sim_swap_error'))
            })
        },

        getCurrentSimDetails({ getters }) {
            const CustomerUUID = getters.current_account_uid
            const ProductInstanceUUID = getters.customer_current_product_uuid
            const Type = getters.is_account_postpay ? 'postpay' : 'prepay'

            if (!CustomerUUID || !ProductInstanceUUID) return Promise.reject(`${i18n.t('error_occurred')}. ${i18n.t('try_again_later')}`)

            return axiosIntents.post('/sim/status', { CustomerUUID, ProductInstanceUUID, Type })
                .then(({result}) => {
                    if (!result) return Promise.reject(`${i18n.t('error_occurred')}. ${i18n.t('try_again_later')}`)

                    return Promise.resolve(result)
                })
                .catch(error => {
                    console.log('getSimEnabledStatus Error:', error)

                    return Promise.reject(`${i18n.t('error_occurred')}. ${i18n.t('try_again_later')}`)
                })
        },
    },

    mutations: {
        SET_SIM(state, sim) {
            state.sim = sim;

            if (sim) {
                dynStorage.set('sim', sim, null, true);
            } else {
                dynStorage.remove('sim');
            }
        },

        RESET_SIM(state) {
            state.sim = {};
            dynStorage.remove('sim');
        },

        SET_SIM_ENABLED_STATUS(state, is_sim_enabled) {
            state.is_sim_enabled = is_sim_enabled;
        },
    },
}