import axiosIntents from '@/utils/axios-intents'
import axios from 'axios'
import dynStorage from '@/utils/storage-dynamic'

export default {
    state: {},

    getters: {},

    actions: {
        LinkTrueRewardsNumber({ getters }, { is_to_link = false, RewardsEmail, Password } = {}) {
            // TODO done only for demo purposes
            if (!is_to_link) {
                return new Promise(resolve => {
                    const REWARDS_CARD_UUIDS = dynStorage.get('REWARDS_CARD_UUIDS', true)
                    const CustomerUUID = getters.current_account_uid
    
                    if (REWARDS_CARD_UUIDS) {
                        delete REWARDS_CARD_UUIDS[CustomerUUID]
    
                        dynStorage.set('REWARDS_CARD_UUIDS', REWARDS_CARD_UUIDS, false, true)
                    }

                    setTimeout(() => {
                        resolve('')
                    }, 1200);
                })
            }

            return axios.post(
                'https://rewardsvc-wggcqrj4kq-ts.a.run.app/api/v3/claim_rewards_card',
                {
                    RewardsEmail,
                    Password,
                    SPID: 67,
                }
            ).then(({data}) => {
                const REWARDS_CARD_UUID = data?.UUID || ''

                if (REWARDS_CARD_UUID) {
                    const REWARDS_CARD_UUIDS = dynStorage.get('REWARDS_CARD_UUIDS', true) || {}
                    const CustomerUUID = getters.current_account_uid
    
                    REWARDS_CARD_UUIDS[CustomerUUID] = REWARDS_CARD_UUID

                    dynStorage.set('REWARDS_CARD_UUIDS', REWARDS_CARD_UUIDS, false, true)
                }

                return REWARDS_CARD_UUID
            })
        },

        UpdateMVNECustomer({}, { FirstName, LastName, ExternalRef5, Email, MobileNumber = '' } = {}) {
            return axiosIntents.put('/customer/:uuid', { FirstName, LastName, ExternalRef5, Email, MobileNumber })
                .catch(error => {
                    console.log('UpdateMVNECustomer error', error)
                })
        },

        SendEmailVerification({}, { resend = false } = {}) {
            return axiosIntents.get(`/customer/:uuid/email_verification/${resend ? 'resend' : 'send'}`)
                .catch(error => {
                    console.log('SendEmailVerification Error:', error)

                    return Promise.resolve(error)
                })
        },

        ConfirmEmailVerification({ getters }, { Secret } = {}) {
            if (!Secret) return Promise.reject('No Code')
            
            const CustomerUUID = getters.current_account_uid

            return axiosIntents.get(`/customer/:uuid/email_verification/confirm`, { params: { Secret, CustomerUUID } })
                .catch(error => {
                    console.log('ConfirmEmailVerification Error:', error)

                    return Promise.reject(error)
                })
        },

        IdentifyCustomerWithToken({ getters }, { identity_token } = {}) {
            console.log('IdentifyCustomerWithToken', identity_token)

            if(!identity_token) Promise.reject('No identity_token')

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({identity_token})

                    // reject('Identification error')
                }, 1200);
            })
        },
    },

    mutations: {},
}